import {Planogram} from '../planogram';
import {HTMLUtils} from '../utils/html_utils';
import {InputEventUtils} from '../utils/input_event_utils';
import {InputHandler} from '../input_handler';

import Router from '../router';
import {UrlUtils} from '../api/urls';
import {AnimationPath} from 'shared/interfaces/planogram';
import {CameraControls} from '../controls/camera_controls';
import {NAVIGATION_ARROW_TYPES} from '../interfaces/planogram.interface';
import {Metrics} from '../metrics';
import {MATOMO_EVENT_NAMES} from '../metric-events';
import {isTouchSupported} from '../utils/platform';

export class NavigationArrows {
  private container: HTMLElement;
  private animationPath: AnimationPath;

  constructor(
    private planogram: Planogram,
    private cameraControls: CameraControls,
    private inputHandler: InputHandler
  ) {
    this.initButtons();
    this.handleClicks();
  }

  private initButtons() {
    this.container = document.getElementById('navigation-arrows-container');
    this.renderView();
    this.applyStyles();
  }

  private renderView() {
    const leftArrow = document.createElement('div');
    const leftArrowIconContainer = document.createElement('div');
    const rightArrow = document.createElement('div');
    const rightArrowIconContainer = document.createElement('div');
    leftArrow.classList.add('navigation-arrow-button', 'left-arrow');
    leftArrowIconContainer.classList.add('navigation-arrow-button-icon-wrapper');
    rightArrow.classList.add('navigation-arrow-button', 'right-arrow');
    rightArrowIconContainer.classList.add('navigation-arrow-button-icon-wrapper');
    leftArrow.appendChild(leftArrowIconContainer);
    rightArrow.appendChild(rightArrowIconContainer);
    this.container.appendChild(leftArrow);
    this.container.appendChild(rightArrow);
  }

  private applyStyles() {
    const root = document.documentElement;
    const {navigationArrow} = this.planogram;
    const leftButton = this.container.querySelector<HTMLElement>('.left-arrow .navigation-arrow-button-icon-wrapper');
    const rightButton = this.container.querySelector<HTMLElement>('.right-arrow .navigation-arrow-button-icon-wrapper');

    root.style.setProperty('--navigation-arrow-bg-color', navigationArrow.background_color);

    if (navigationArrow.background_color) {
      root.style.setProperty('--navigation-arrow-glow-color', `${navigationArrow.background_color}3d`);
    }

    if (navigationArrow.distribute_arrows && isTouchSupported) {
      this.container.classList.add('distribute');
    }

    if (navigationArrow.disable_glow_effect) {
      this.container.classList.add('without-glow');
    }

    this.addIcon(leftButton, NAVIGATION_ARROW_TYPES.LEFT);
    this.addIcon(rightButton, NAVIGATION_ARROW_TYPES.RIGHT);
  }

  private addIcon(button: HTMLElement, direction: NAVIGATION_ARROW_TYPES): HTMLElement {
    const img = this.planogram.createImage(direction, '');
    button.appendChild(img);

    return button;
  }

  private updateUrl() {
    const animation = this.animationPath?.items?.[this.inputHandler.currentAnimationIndex] ?? null;

    if (animation) {
      Router.navigateToPlanogramWithPath(
        animation.planogramName,
        this.animationPath.name,
        {
          langCode: animation.language,
          replace: true,
          silent: true
        },
        this.inputHandler.currentAnimationIndex
      );
      this.inputHandler.handleInitAnimationPath(this.animationPath.name, true);
    }
  }

  private handleClicks() {
    const previousArrow = this.container.querySelector<HTMLElement>('.left-arrow');
    const nextArrow = this.container.querySelector<HTMLElement>('.right-arrow');
    const animationIdentifier = this.planogram.navigationArrow.animation_path?.name;
    this.animationPath = this.inputHandler.handleInitAnimationPath(animationIdentifier, true);

    InputEventUtils.addSelectEvents(previousArrow, () => {
      if (!this.animationPath || this.inputHandler.animationPath.uuid !== this.animationPath.uuid) {
        this.animationPath = this.inputHandler.handleInitAnimationPath(animationIdentifier, true);
      } else {
        this.inputHandler.handleNextAnimation(true);
      }
      this.updateUrl();
      Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.NAVIGATION_ARROW_CLICK('previous'));
    });

    InputEventUtils.addSelectEvents(nextArrow, () => {
      if (!this.animationPath || this.inputHandler.animationPath.uuid !== this.animationPath.uuid) {
        this.animationPath = this.inputHandler.handleInitAnimationPath(animationIdentifier, true);
      } else {
        this.inputHandler.handleNextAnimation(false);
      }
      this.updateUrl();
      Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.NAVIGATION_ARROW_CLICK('next'));
    });
  }

  dispose() {
    this.animationPath = undefined;
    this.container.classList.remove('without-glow', 'distribute');
    HTMLUtils.removeChildElements(this.container);
  }
}
